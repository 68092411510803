<template>
  <div class="contentDetail ">
    <div class="goTo" @click="goHome">
      首页
    </div>
    <div class="logo-con">
      <div class="clogo">
        <div class="logo_ico">

        </div>
        <div class="h3">
          <span class="circular">·</span>
          <p>{{ data.name }}</p>
          <span class="circular">·</span>
        </div>
      </div>
      
    </div>
    <van-swipe
      class="my-swipe"
      height="200"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(image, index) in imgList" :key="index">
        <div
          class="image"
          :style="{
            background: 'url(' + image.image + ')  no-repeat center center ',
          }"
        ></div>
      </van-swipe-item>
    </van-swipe>
    <div class="txt ani animated fadeInUp">
       <h4 v-if="data.seriesId===1">寓见高行·原宿</h4>
           <h4 v-if="data.seriesId===2">寓见高行·安居</h4>
           <h4 v-if="data.seriesId===3">寓见高行·宜居</h4>
           <h4 v-if="data.seriesId===4">寓见高行·品质</h4>
           <h4 v-if="data.seriesId===5">寓见高行·卓越</h4>
      <p>
        {{ data.introduction }}
      </p>
    </div>
     <div class="footer">
       <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022002995号</a>
    </div>
  </div>
</template>

<script>
import { project } from "@/api/project";
export default {
  components: {},
  data() {
    return {
      imgList: [
      
      ],
      data: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
     goHome(){
        this.$router.push({
        path: "/",
       
      });
    },
    async getData() {
      let id = this.$route.query.id;
      let { result } = await project({ id: id });
      this.imgList = result[0].images;
      this.data = result[0];
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.contentDetail {
  background: #000;
}
.logo-con{
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
     justify-content: center;
  .clogo{
  text-align: center;
 
  .logo_ico{
    display: inline-block;
     width: 4rem;
  height: 4rem;
  background: #989898;
  background: url('../assets/logo.png') no-repeat center center;
    background-size:contain ;

  }
}
}

.swiper-container {
  height: 30%;
  .swiper-slide {
    .img {
      width: 100%;
      height: 100%;
      background-size: cover !important;
    }
  }

  /deep/ .swiper-pagination-bullet {
    background: #989898;
    opacity: 1;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: #fff;
  }
  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
  /deep/ .swiper-wrapper {
    transition: all 0.5s linear !important;
  }
}
.txt {
  width: 100%;
  padding: 1rem;
  color: #fff;
  box-sizing: border-box;
  h4 {
    text-align: center;
    font-size: 1.4rem;
    letter-spacing: 1.4rem;
    text-indent: 1.4rem;
  }
  p {
    font-size: 1rem;
    color: #e0fdfd;
    text-indent: 2.8rem;
    line-height: 2rem;
  }
}
.my-swipe {
  .image {
    width: 100%;
    height: 100%;
    background-size: cover !important;
  }
}
 .h3 {
      font-size: 1rem;
      color: #fff;
      font-weight: 700;

      margin: 10px 0;
      text-align: center;
      line-height: 30px;
      margin: 0;
      p {
        display: inline-block;
        letter-spacing: 5px;
        text-indent: 5px;
      }
      .circular {
        display: inline-block;
        color: #388c88;
      }
    }
    .goTo{
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999999;
  width: 34px;
  height: 34px;
  font-size: 12px;
  border-radius: 50%;
  background: #388c88;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

</style>
